import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Gis from "/src/components/img/gis"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Co je mapová kompozice?" />
    <h1>Co je mapová kompozice?</h1>

    <p><strong>Mapov&aacute; kompozice</strong>&nbsp;je v kartografii a geoinformatice definov&aacute;na jako vlastn&iacute; rozložen&iacute; jednotliv&yacute;ch prvků kartografick&eacute;ho d&iacute;la na vybran&eacute;m zobrazovac&iacute;m m&eacute;diu. Podl&eacute;h&aacute; hlavně &uacute;čelu mapy, zvolen&eacute;mu měř&iacute;tku, kartografick&eacute;mu zobrazen&iacute;, tvaru a velikosti vyobrazen&eacute;ho &uacute;zem&iacute; a form&aacute;tu dan&eacute;ho m&eacute;dia.&nbsp;Kompozice mapy ře&scaron;&iacute; v&yacute;slednou&nbsp;<strong>maketu mapy (zrcadlo mapy)</strong>, v př&iacute;padech, kter&eacute; to vyžaduj&iacute;, pak d&aacute;le klad listů, tematickou posloupnost, ře&scaron;en&iacute; rejstř&iacute;ku aj.</p>
    <hr /><h2>Maketa mapy</h2>
    <p>Maketa mapy<strong>&nbsp;(zrcadlo mapy)</strong>&nbsp;je grafick&eacute; rozm&iacute;stěn&iacute; obrysů v&scaron;ech kompozičn&iacute;ch prvků v r&aacute;mci jednoho form&aacute;tu mapy, mapov&eacute;ho listu, strany atlasu apod. Často se v souvislosti s použ&iacute;v&aacute;n&iacute;m GIS softwaru objevuje slovo layout.</p>
    <hr /><h2>Mapov&eacute; kompozičn&iacute; prvky</h2>
    <h3>Z&aacute;kladn&iacute; kompozičn&iacute; prvky</h3>
    <p>Z&aacute;kladn&iacute; kompozičn&iacute; prvky nesm&iacute; až na ojediněl&eacute; v&yacute;jimky chybět ve v&yacute;sledn&eacute; kompozici. Mezi ně patř&iacute;:</p>
    <ul>
    <li><strong>mapov&eacute; pole</strong></li>
    <li><strong>n&aacute;zev mapy</strong></li>
    <li><strong>měř&iacute;tko</strong>&nbsp;(nejčastěji grafick&eacute;)</li>
    <li><strong>legenda</strong></li>
    <li><strong>tir&aacute;ž</strong></li>
    </ul>
    <h3>Nadstavbov&eacute; kompozičn&iacute; prvky</h3>
    <p>Nadstavbov&eacute; kompozičn&iacute; prvky&nbsp;<strong>(margin&aacute;lie)</strong>&nbsp;zvy&scaron;uj&iacute; atraktivnost mapy a roz&scaron;iřuj&iacute; ji o dal&scaron;&iacute; informace. Jejich použit&iacute; mus&iacute; b&yacute;t pečlivě zv&aacute;ženo, př&iacute;li&scaron; vysok&yacute; počet margin&aacute;li&iacute; totiž může ne&uacute;měrně zat&iacute;žit mapu a sn&iacute;žit tak jej&iacute; čitelnost a přehlednost. Proveden&iacute; těchto prvků by mělo odpov&iacute;dat jejich v&yacute;znamu v celkov&eacute; kompozici, je třeba se tak vyvarovat př&iacute;li&scaron;n&eacute; v&yacute;raznosti (plocha, barva atp.).</p>
    <ul>
    <li><strong>Grafick&eacute; margin&aacute;lie</strong></li>
    <ul>
    <li><strong>směrovka</strong> (povinn&aacute; souč&aacute;st mapy, pokud neobsahuje zeměpisnou s&iacute;ť, nejedn&aacute; se o zn&aacute;m&eacute; &uacute;zem&iacute; orientovan&eacute; k severu nebo se nejedn&aacute; o souč&aacute;st souboru, který je celý orientován k severu),</li>
    <li>vedlej&scaron;&iacute; mapy,</li>
    <li>příčné či podélné profily,</li>
    <li>grafy,</li>
    <li>fotografie aj.</li>
    </ul>
    <li><strong>Textov&eacute; margin&aacute;lie</strong></li>
    <ul>
    <li>doplňkov&eacute; texty,</li>
    <li>tabulky aj.</li>
    </ul>
    </ul>
    <h3>Jednotliv&eacute; prvky podrobněji</h3>
    <p>O jednotlivých mapových kompozičních prvcích a zásadách jejich tvorby lze napsat mnohé. Proto byly vybrané prvky vyčleněny do samostatn&yacute;ch podkapitol:</p>
<div className="od-sm"><hr />
    <h4><Link to="/gis/mapova-kompozice/meritko/">Měřítko mapy</Link></h4>
    <p>Měřítko je obvykle povinnou součástí mapy. Uživateli totiž prozradí, kolik metrů ve skutečnosti odpovídá jednomu centimetru na mapě.</p>
<hr />
    <h4><Link to="/gis/mapova-kompozice/legenda/">Legenda mapy</Link></h4>
    <p>Bez tzv. mapového klíče by šlo zejména na tematických mapách jen těžce rozklíčovat, co který symbol reprezentuje za jevy a objekty.</p>
<hr />
    <h4><Link to="/gis/mapova-kompozice/nazev-mapy/">Název mapy</Link></h4>
    <p>Věcný název, který trefně vyjadřuje místní, časové i věcné určení díla, by neměl za normálních okolností na mapě chybět.</p>
<hr />
<h4><Link to="/gis/mapova-kompozice/smerovka/">Směrovka na mapě</Link></h4>
<p>Směrovka se sice řadí mezi nadstavbové kompoziční prvky, v mnoha případech je však její umístění na mapu povinné. Graficky totiž znázorňuje orientaci mapy ku světovým stranám.</p>
<hr />
    </div>
    <hr />
    <ImgCon><Gis /><div>
    <h2>Studijn&iacute; materi&aacute;ly GIS, geoinformatika a&nbsp;kartografie</h2>
    <p>T&eacute;ma <strong>Mapová kompozice</strong> je souč&aacute;st&iacute; <Link to="/gis/">studijn&iacute;ch materi&aacute;lů GIS, geoinformatika a kartografie</Link> vytvořených zejména na podkladě předmětů vyučovaných na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat, případně na téma předch&aacute;zej&iacute;c&iacute; či n&aacute;sleduj&iacute;c&iacute;.</p>
    <BtnWrap>
    <Link to="/gis/"><button>Rozcestník GIS</button></Link>
    <Link to="/gis/"><button className="inv">&larr; Nedokončeno</button></Link>
    <Link to="/gis/mapova-kompozice/meritko/"><button className="inv">Měřítko mapy &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
